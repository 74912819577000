<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";
import { VMoney } from 'v-money';
import axios from 'axios';
import appConfig from "../../../../src/app.config";

export default {
  locales: {
    pt: {
      'Send Receipts': 'Enviar Comprovante',
      'Send': 'Enviar',
      'Next': 'Próximo',
    },
    es: {
      'Send Receipts': 'Enviar Comprovantes',
      'Send': 'Enviar',
      'Next': 'Siguiente',
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      companies: [],

      alert: {
        type: '',
        message: '',
        params: [],
      },

      step: 1,

      receipt: {
        loading: false,
        success: false,

        company: '',
        order: '',
        date: '',
        total: '',
        file: '',
      },

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2
      },
    };
  },
  directives: { money: VMoney },
  validations: {
    receipt: {
      company: { required },
      order: { required },
      date: { required },
      total: { required },
    }
  },
  methods: {
    getCompanies() {
      api
        .get('partners/companies')
        .then((response) => {
          if (response.data.status == 'success') {
            this.companies = response.data.list
          }
        })
    },
    sendReceipt() {
      this.receipt.loading = true
      this.$v.receipt.$touch();

      if (this.receipt.company && this.receipt.order && this.receipt.date && this.receipt.total && this.receipt.file) {
        api
          .post('partners/receipts', {
            company: this.receipt.company,
            order: this.receipt.order,
            date: this.receipt.date,
            total: this.receipt.total,
          })
          .catch(error => {
            if (error) {
              this.receipt.loading = false
            }
          })
          .then(response => {
            if (response.data.status=='success') {
              this.$v.$reset()
              this.receipt.id = response.data.receipt.id
              this.uploadFile(response.data.receipt.id)
            } else {
              this.step = 1

              this.alert.type = 'alert-danger'
              this.alert.message = response.data.message
              if (response.data.params) {
                this.alert.params = response.data.params
              } else {
                this.alert.params = []
              }

              this.$v.$reset()
              this.receipt.loading = false
            }
          })
      }
    },
    uploadFile(uuid) {
      let data = new FormData();
      data.append('file', this.receipt.file);

      var config = {
        headers: {
          'x-auth-token': localStorage.token,
          'Content-Type': 'multipart/form-data',
        },
      };

      axios
        .post(appConfig.apiUrl+'partners/receipts/'+uuid+'/file', data, config)
        .catch(error => {
          if (error) {
            this.receipt.loading = false
          }
        })
        .then((response) => {
          if (response.data.status=='success') {
            this.step = 3
          } else {
            this.step = 4
          }

          this.receipt.loading = false
        });
    },
    setStep(step) {
      this.step = step

      this.alert.type = ''
      this.alert.message = ''
      this.alert.params = []
    },
    onFileSelected() {
      this.receipt.file = this.$refs.file.files[0];
    },
  },
  mounted() {
    this.getCompanies()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Send Receipts') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-7">
        <div class="card">
          <div class="card-body p-4">
            <b-form class="p-3" @submit.prevent="sendReceipt">
              <div v-if="alert.message" :class="'alert ' + alert.type">{{ t(alert.message) }}</div>
              <div v-if="step == 1">
                <div class="border-bottom row mb-4 pb-3">
                  <div class="col-4 align-self-center"><button class="btn btn-link p-0 text-dark text-decoration-none"><i class="bx bx-news font-size-22 text-dark align-top"></i></button></div>
                  <div class="col-4 align-self-center text-center">Etapa 1 de 2</div>
                  <div class="col-4 align-self-center"></div>
                </div>
                <b-form-group id="company" :label="t('Empresa')" label-for="company">
                  <select v-if="companies.length > 0" v-model="receipt.company" class="custom-select mb-2">
                    <option v-for="b in companies" :key="b.id" :value="b.id">{{ b.name }}</option>
                  </select>
                  <div v-else>
                    <b-spinner small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                  </div>
                  <div v-if="$v.receipt.company.$error" class="invalid-feedback">
                    <span v-if="!$v.receipt.company.required">{{ t('New password is required.') }}</span>
                  </div>
                </b-form-group>
                <b-form-group id="order" :label="t('Número do Pedido')" label-for="order">
                  <b-input-group>
                    <b-form-input id="order" size="lg" v-model="receipt.order" type="text" inputmode="decimal" class="rounded" :class="{ 'is-invalid': $v.receipt.order.$error }"></b-form-input>
                  </b-input-group>
                  <div v-if="$v.receipt.order.$error" class="text-danger font-size-12">
                    <span v-if="!$v.receipt.order.required">{{ t('Digite o valor do aporte.') }}</span>
                  </div>
                </b-form-group>
                <b-form-group id="date" :label="t('Data do Pedido')" label-for="date">
                  <b-input-group>
                    <b-form-input id="date" size="lg" v-model="receipt.date" type="date" inputmode="decimal" class="rounded" :class="{ 'is-invalid': $v.receipt.date.$error }"></b-form-input>
                  </b-input-group>
                  <div v-if="$v.receipt.date.$error" class="text-danger font-size-12">
                    <span v-if="!$v.receipt.date.required">{{ t('Digite o valor do aporte.') }}</span>
                  </div>
                </b-form-group>
                <b-form-group id="total" :label="t('Valor do Pedido')" label-for="total">
                  <b-input-group>
                    <b-form-input id="total" size="lg" v-model="receipt.total" v-money="money" type="text" inputmode="decimal" class="rounded" :class="{ 'is-invalid': $v.receipt.total.$error }"></b-form-input>
                  </b-input-group>
                  <p class="text-muted">* O valor do pedido é somente o valor dos produtos adquiridos</p>
                  <div v-if="$v.receipt.total.$error" class="text-danger font-size-12">
                    <span v-if="!$v.receipt.total.required">{{ t('Digite o valor do aporte.') }}</span>
                  </div>
                </b-form-group>
                <div class="mt-4">
                  <b-button :disabled="this.receipt.company === '' || this.receipt.no === '' || this.receipt.date === '' || this.receipt.total==='R$ 0,00'" size="lg" variant="default" @click="setStep(2)">
                    {{ t('Next') }}
                  </b-button>
                </div>
              </div>
              <div v-else-if="step == 2">
                <div class="border-bottom row mb-4 pb-3">
                  <div class="col-4 align-self-center"><button class="btn btn-link p-0 text-dark text-decoration-none" @click="setStep(1)"><i class="bx bx-arrow-back font-size-22 text-dark align-bottom"></i> <span class="d-none d-sm-inline-block">Voltar</span></button></div>
                  <div class="col-4 align-self-center text-center">Etapa 2 de 2</div>
                  <div class="col-4 align-self-center"></div>
                </div>
                <b-form-group id="file" :label="t('Selecione o arquivo do comprovante')" label-for="file">
                  <b-input-group>
                    <input type="file" id="file" ref="file" @change="onFileSelected" aria-label="Upload" class="p-4 border rounded w-100">
                  </b-input-group>
                </b-form-group>
                <b-button :disabled="this.receipt.loading == true || this.receipt.company === '' || this.receipt.no === '' || this.receipt.date === '' || this.receipt.total==='R$ 0,00' || this.receipt.file === ''" size="lg" type="submit" variant="default">
                  {{ t('Send') }}
                  <b-spinner v-if="receipt.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                </b-button>
              </div>
              <div v-else-if="step == 3">
                <div class="row justify-content-center">
                  <div class="col-md-7 my-4 text-center">
                    <img style="width:150px;" src="@/assets/images/rating.png" />
                    <h3 class="mt-4">Seu comprovante foi enviado!</h3>
                    <h5 class="">Agora é só aguardo um de nossos consultores validar seu comprovante.</h5>
                  </div>
                </div>
                <div class="text-center my-4">
                  <router-link class="btn btn-default btn-lg px-5" tag="a" :to="'/partners/receipts/' + this.receipt.id">Ver Comprovante</router-link>
                </div>
              </div>
              <div v-else-if="step == 4">
                <div class="row justify-content-center">
                  <div class="col-md-7 my-4 text-center">
                    <img style="width:150px;" src="@/assets/images/warning.png" />
                    <h3 class="mt-4">O arquivo do seu comprovante não foi enviado!</h3>
                    <h5 class="">Acesse o link abaixo que você poderá reenviar o arquivo do seu comprovante.</h5>
                  </div>
                </div>
                <div class="text-center my-4">
                  <router-link class="btn btn-default btn-lg px-5" tag="a" :to="'/partners/receipts/' + this.receipt.id">Ver Comprovante</router-link>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
